const exporterPaymentData = [
    {
        label: "Antecipado",
        value: "ANTECIPADO",
    },
    {
        label: "A Prazo",
        value: "A_PRAZO",
    },
    {
        label: "Vendemmia",
        value: "VENDEMMIA",
    },
    {
        label: "Cliente",
        value: "CLIENTE",
    },
    {
        label: "Fininp",
        value: "FININP",
    },
    {
        label: "Recursos Próprios",
        value: "RECURSOS_PROPRIOS",
    },
    {
        label: "Adiantamento",
        value: "ADIANTAMENTO",
    }
];

export default exporterPaymentData;